<template>
  <v-data-table
    :headers="tableHeaders"
    :items="tableData"
    :items-per-page="10"
    class="elevation-1"
    dense
    filterable
    hide-default-footer
  ></v-data-table>
</template>

<script>
export default {
  name: "DatasetTablePreview",
  props: {
    tableHeaders: { type: Array, required: true },
    tableData: { type: Array, required: true },
  },
};
</script>

<style scoped lang="scss">
</style>
