<template>
  <v-row>
    <v-col class="col-12 col-sm-auto pl-6">
      <v-row>
        <span class="dataset-numbers">{{ columns }}</span>
      </v-row>
      <v-row>
        <span class="dataset-numbers-text pl-2">colonne</span>
      </v-row>
    </v-col>
    <v-col class="col-12 col-sm-auto pl-sm-16">
      <v-row>
        <span class="dataset-numbers">{{ rows }}</span>
      </v-row>
      <v-row>
        <span class="dataset-numbers-text pl-2">righe</span>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DatasetNumbers",
  props: {
    rows: { type: String, required: true },
    columns: { type: Number, required: true },
  },
};
</script>

<style scoped lang="scss">
.dataset-numbers {
  color: #2ab5b9;
  font: normal normal bold 54px/74px Open Sans;
  letter-spacing: 0px;
}
.dataset-numbers-text {
  font: normal normal normal 18px/24px Open Sans;
  letter-spacing: 0px;
}
</style>
