<template>
  <div>
    <v-data-table
      :headers="columnDetailHeaders"
      :items="
        showAllDatasetColumns ? columnDetailData : displayColumnDetailData
      "
      :items-per-page="999"
      class="elevation-1 detail-table"
      dense
      filterable
      hide-default-footer
    >
      <template v-slot:[`item.name`]="{ item }">
        <span class="name-text">
          {{ item.name }}
        </span>
      </template></v-data-table
    >
    <v-card v-if="columnDetailData.length > 10" class="detail-table-footer">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          normal
          v-if="!showAllDatasetColumns"
          @click="showAllDatasetColumns = true"
          color="#2ab5b9"
          >Mostra tutto ({{ columnDetailData.length }})</v-btn
        >
        <v-btn
          text
          normal
          v-else
          @click="showAllDatasetColumns = false"
          color="#2ab5b9"
          >Mostra meno</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DatasetColumnDetail",
  data() {
    return {
      columnDetailHeaders: [
        {
          text: "Nome della colonna",
          value: "name",
        },
        {
          text: "Descrizione",
          value: "alias",
        },
        {
          text: "Tipologia",
          value: "datatype",
        },
      ],
      showAllDatasetColumns: false,
    };
  },
  props: {
    columnDetailData: { type: Array, required: true },
  },
  computed: {
    displayColumnDetailData() {
      return this.columnDetailData?.slice(0, 10) ?? [];
    },
  },
};
</script>

<style scoped lang="scss">
.detail-table {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  .name-text {
    color: #2ab5b9;
  }
}
.detail-table-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 5px 1px -2px rgba(0, 0, 0, 0.2),
    0px 5px 2px 0px rgba(0, 0, 0, 0.14), 0px 5px 5px 0px rgba(0, 0, 0, 0.12);
}
</style>
