<template>
  <p v-if="isLink(license)" class="license-text">
    <a :href="license" target="_blank" class="licence-link">{{ license }}</a>
  </p>
  <div v-else-if="isCC(license)">
    <a rel="license" href="http://creativecommons.org/licenses/by/4.0/"
      ><img
        alt="Licenza Creative Commons"
        class="img-border"
        src="https://i.creativecommons.org/l/by/4.0/88x31.png" /></a
    ><br />
    <p class="license-text">
      Quest'opera è distribuita con Licenza
      <a rel="license" href="http://creativecommons.org/licenses/by/4.0/"
        >Creative Commons Attribuzione 4.0 Internazionale</a
      >.
    </p>
  </div>
  <p v-else>{{ license }}</p>
</template>

<script>
export default {
  name: "DatasetLicense",
  props: {
    license: { type: String, required: true },
  },
  methods: {
    isLink(license) {
      return license.includes("http");
    },
    isCC(license) {
      return license.includes("CC BY 4.0");
    },
  },
};
</script>

<style scoped lang="scss">
.img-border {
  border-width: 0;
}
.licence-link {
  font-weight: bold;
  word-break: break-word;
}
.license-text {
  font: normal normal normal 14px/18px Open Sans;
  letter-spacing: 0px;
  word-break: break-word;
}
a {
  color: #2ab5b9;
}
</style>
