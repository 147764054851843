<template>
  <div class="catalogo-detail">
    <!-- page title -->
    <page-title></page-title>
    <back-button></back-button>

    <!-- page body -->
    <v-container class="pt-8">
      <!-- left section -->
      <v-row>
        <v-col cols="12" md="8">
          <!-- header -->
          <v-row class="px-md-4">
            <v-col class="col-auto col-md-9">
              <p class="dataset-title">{{ titolo }}</p>
            </v-col>
            <v-col class="col-auto col-md-3 d-flex justify-end">
              <share-button></share-button>
            </v-col>
          </v-row>

          <!-- topics -->
          <v-row class="px-md-4">
            <div v-for="topic in topics" :key="'topic' + topic">
              <topic-chip class="pl-3" :topic="topic"></topic-chip>
            </div>
          </v-row>

          <!-- description -->
          <p class="dataset-description px-md-4">{{ description }}</p>

          <!-- tags -->
          <div class="px-md-4">
            <p class="section-title pt-4">Tag</p>
            <v-chip
              v-for="tag in tags"
              :key="'tag' + tag"
              class="mr-2 mt-4 tag-chip"
              color="#008080"
              outlined
            >
              <span class="tag-text">{{ tag.toLowerCase() }}</span>
            </v-chip>
          </div>

          <!-- time info -->
          <div class="time-info pt-8 px-md-4">
            <p class="section-title">Informazioni temporali</p>
            <p class="info-title">Ultimo aggiornamento scheda metadato</p>
            <p class="text">{{ metadataModified | numbDate }}</p>
            <p class="info-title">Data aggiornamento Dataset</p>
            <p class="text">{{ dataModified | numbDate }}</p>
            <p class="info-title">Data di creazione</p>
            <p class="text">{{ dataCreated | numbDate }}</p>
            <p class="info-title">Frequenza di aggiornamento Dataset</p>
            <p class="text">{{ updateRate }}</p>
          </div>

          <!-- metadata info -->
          <div class="metadata-info px-md-4" v-if="metadata.length == 0">
            <p class="section-title pt-8">Ulteriori Informazioni</p>
            <p class="info-title">Nome dell'Autore</p>
            <p class="text">{{ organization }}</p>
            <p class="info-title">Nome del Titolare</p>
            <p class="text">{{ owner }}</p>
          </div>
        </v-col>

        <!-- right section -->
        <v-col cols="12" md="4">
          <!-- links -->
          <v-row>
            <div
              v-for="link in links"
              :key="link.key"
              class="ml-4 mt-3 links-list"
            >
              <v-btn color="#29CED3">
                <a :href="link.value" target="_blank">{{ link.name }}</a>
              </v-btn>
            </div>
          </v-row>

          <!-- owner img -->
          <p class="section-title pt-4">Ente titolare</p>
          <v-img
            class="mb-8"
            :src="baseUrl + enteImg"
            max-height="200"
            contain
            position="top left"
          ></v-img>

          <!-- owner info -->
          <p class="info-title mb-2">Pubblicato da:</p>
          <p class="text">{{ organization }}</p>
          <p class="info-title mb-2">Fonte:</p>
          <p class="text">{{ fonte }}</p>
          <p class="info-title mb-2">URL fonte metadato:</p>
          <p class="text" v-if="displayMetadataUrl != '-'">
            <a :href="metadataUrl" target="_blank">{{ displayMetadataUrl }}</a>
          </p>
          <p class="text" v-else-if="displaySourceUrl != '-'">
            <a :href="sourceUrl" target="_blank">{{ displaySourceUrl }}</a>
          </p>
          <p v-else class="text">{{ displayMetadataUrl }}</p>

          <!-- dataset downloads and views -->
          <v-row v-if="views != '-' || downloads != '-'">
            <v-col class="col-12 col-sm-auto pl-6">
              <v-row>
                <span class="downloads-numbers">{{ views }}</span>
              </v-row>
              <v-row>
                <span class="downloads-numbers-text pl-1 pb-8"
                  >visualizzazioni API</span
                >
              </v-row>
            </v-col>
            <v-col class="col-12 col-sm-auto pl-sm-16">
              <v-row>
                <span class="downloads-numbers">{{ downloads }}</span>
              </v-row>
              <v-row>
                <span class="downloads-numbers-text pl-1">scarichi CSV</span>
              </v-row>
            </v-col>
          </v-row>

          <!-- license -->
          <p class="section-title">Licenza</p>
          <div v-for="license in constraints" :key="license">
            <dataset-license :license="license"></dataset-license>
          </div>
        </v-col>

        <!-- dataset numbers -->
        <v-col
          v-if="isSmartdatanet && columnDetailData.length != 0"
          cols="12"
          md="8"
          class="px-md-12"
        >
          <p class="section-title pt-8 mb-0">
            Cosa è contenuto in questo dataset?
          </p>
          <dataset-numbers
            :rows="tableDataRows.toString()"
            :columns="columnDetailData.length"
          ></dataset-numbers>
        </v-col>

        <!-- column details -->
        <v-col
          v-if="isSmartdatanet && columnDetailData.length != 0"
          class="px-md-12 col-12"
        >
          <p class="section-title pt-8">Colonne in questo Dataset</p>
          <dataset-column-detail
            :columnDetailData="columnDetailData"
          ></dataset-column-detail>
        </v-col>

        <!-- table preview -->
        <v-col
          v-if="isSmartdatanet && tableData.length != 0"
          class="px-md-12 col-12"
        >
          <v-row class="justify-space-between pt-8 px-4">
            <p class="section-title">Anteprima Dataset</p>
            <v-btn v-if="csvHref" color="#29CED3" :href="csvHref" target="_blank">
              <v-icon>get_app</v-icon>
            </v-btn>
          </v-row>
          <dataset-table-preview
            :tableHeaders="tableHeaders"
            :tableData="tableData"
          ></dataset-table-preview>
        </v-col>

        <v-col cols="12" md="8" class="px-md-12">
          <!-- metadata info -->
          <div v-if="metadata.length != 0">
            <p class="section-title pt-8">Ulteriori Informazioni</p>
            <div class="metadata-info">
              <p class="info-title">Nome dell'Autore</p>
              <p class="text">{{ CreatorName }}</p>
              <p class="info-title">Tipo di Autore</p>
              <p class="text">{{ CreatorType }}</p>
              <p class="info-title">Identificativo Autore</p>
              <p class="text">{{ CreatorId }}</p>
              <p class="info-title">Nome del Titolare</p>
              <p class="text">{{ RightsHolderName }}</p>
              <p class="info-title">Tipo di Titolare</p>
              <p class="text">{{ RightsHolderType }}</p>
              <p class="info-title">Identificativo del Titolare</p>
              <p class="text">{{ RightsHolderId }}</p>
              <p class="info-title">Nome Organizzazione</p>
              <p class="text">{{ NomeOrg }}</p>
              <p class="info-title">E-Mail Organizzazione</p>
              <p class="text">{{ EmailOrg }}</p>
            </div>
          </div>

          <!-- comments section -->
          <p class="section-title pt-8" v-if="commenti.length > 0">Commenti</p>

          <div class="comments-container">
            <dataset-comment
              :comment="comment"
              v-for="comment in commenti"
              :key="comment.id_invio"
              class="px-4"
            ></dataset-comment>
          </div>

          <!-- contact form -->
          <p class="section-title pt-8">Aggiungi un commento</p>
          <div class="pt-8">
            <contact-form @submitForm="submitForm"></contact-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import BackButton from "../components/BackButton";
import ContactForm from "../components/ContactForm";
import TopicChip from "../components/TopicChip";
import ShareButton from "../components/ShareButton";
import DatasetTablePreview from "../components/DatasetTablePreview";
import DatasetColumnDetail from "../components/DatasetColumnDetail";
import DatasetLicense from "../components/DatasetLicense";
import DatasetNumbers from "../components/DatasetNumbers";
import DatasetComment from "../components/DatasetComment";
import { CATALOGO } from "../router/routes";
import {
  getEnti,
  addComment,
  getComments,
  loadDatasetList,
  getTableData,
  getDatasetMetadata,
  getHomeTopicsList,
} from "../services/api";
import { SOURCE_LIST } from "../boot/globals";
export default {
  name: "CatalogoDetail",
  components: {
    PageTitle,
    BackButton,
    ContactForm,
    TopicChip,
    ShareButton,
    DatasetTablePreview,
    DatasetColumnDetail,
    DatasetLicense,
    DatasetNumbers,
    DatasetComment,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      links: [],
      ente: null,
      commenti: [],
      tableData: [],
      tableDataRows: 0,
      tableHeaders: [],
      metadata: [],
      metadataUrl: null,
      columnDetailData: [],
    };
  },
  computed: {
    dataset() {
      return this.$store.getters["getDatasetDetail"];
    },
    id() {
      return this.dataset?.id ?? "";
    },
    titolo() {
      return this.dataset?.title ?? "";
    },
    topics() {
      return this.dataset?.topics ?? [];
    },
    description() {
      return this.dataset?.description ?? "";
    },
    tags() {
      return this.dataset?.tags ?? [];
    },
    dataCreated() {
      return this.dataset?.dataCreated ?? null;
    },
    metadataModified() {
      return this.dataset?.metadataModified ?? null;
    },
    dataModified() {
      return this.dataset?.dataModified ?? null;
    },
    updateRate() {
      return this.dataset?.updateRate ?? "";
    },
    organization() {
      return this.dataset?.organization ?? "";
    },
    owner() {
      return this.dataset?.owner ?? "";
    },
    csvHref() {
      let link = this.links.filter((r) => {
        return r.name == "CSV";
      });
      return link[0]?.value ?? "";
    },
    odata() {
      return this.dataset?.ODATA ?? "";
    },
    constraints() {
      return this.dataset?.constraints ?? "";
    },
    sourceUrl() {
      return this.dataset?.sourceUrl ?? "-";
    },
    downloads() {
      return this.dataset?.downloads ?? "-";
    },
    views() {
      return this.dataset?.views ?? "-";
    },
    fonte() {
      let key = this.dataset?.sourceFormatDialect ?? "";
      return SOURCE_LIST[key];
    },
    entiList() {
      return this.$store.getters["getEntiList"] ?? [];
    },
    enteImg() {
      return this.ente?.url_relativa_img ?? "";
    },
    isSmartdatanet() {
      return this.id.indexOf("smartdatanet.it") != -1;
    },
    CreatorName() {
      return this.metadata?.dcatCreatorName ?? "";
    },
    CreatorType() {
      return this.metadata?.dcatCreatorType ?? "";
    },
    CreatorId() {
      return this.metadata?.dcatCreatorId ?? "";
    },
    RightsHolderName() {
      return this.metadata?.dcatRightsHolderName ?? "";
    },
    RightsHolderType() {
      return this.metadata?.dcatRightsHolderType ?? "";
    },
    RightsHolderId() {
      return this.metadata?.dcatRightsHolderId ?? "";
    },
    NomeOrg() {
      return this.metadata?.dcatNomeOrg ?? "";
    },
    EmailOrg() {
      return this.metadata?.dcatEmailOrg ?? "";
    },
    displayMetadataUrl() {
      return this.metadataUrl ? this.metadataUrl.split("/api/")[0] : "-";
    },
    displaySourceUrl() {
      return this.sourceUrl ? this.sourceUrl.split(".it/")[0] + ".it" : "-";
    },
    topicsList() {
      return this.$store.getters["getHomeTopicsList"];
    },
  },
  async created() {
    this.$emit("setLoading", true);

    // load data if from direct url
    if (!this.dataset?.id) {
      let payload = {
        query: "*",
        pageIndex: 0,
        pageSize: 999,
        sorting: [],
        owners: [],
        tags: [],
        topics: [],
        advanced: false,
      };
      let datasetPromise = loadDatasetList(payload);

      try {
        let { data } = await datasetPromise;
        let detail = data.datasets.filter((r) => {
          return r.id == this.$route.params.id;
        });
        console.log(detail);
        this.$store.dispatch("setDatasetDetail", {
          datasetDetail: detail[0],
        });

        if (this.isSmartdatanet) {
          // metadata
          this.loadMetadata();
          // tableData
          //this.getTableData();
        }

        // redirect if not found dataset
        if (!this.dataset) {
          this.$router.push({ name: CATALOGO.name });
        }
      } catch (err) {
        console.log(err);
      }

      if (this.topicsList.length == 0) {
        let topicsListPromise = getHomeTopicsList();

        try {
          let { data } = await topicsListPromise;
          this.$store.dispatch("setHomeTopicsList", {
            topicsList: data,
          });
        } catch (err) {
          console.log(err);
        }
      }
    }

    // link list
    this.setLinks();

    // icone enti
    this.getEntiIcon();

    // commenti
    this.loadCommenti();

    this.$emit("setLoading", false);
  },
  updated() {
    if (
      this.isSmartdatanet &&
      this.tableData.length == 0 &&
      this.tableHeaders.length == 0 &&
      this.metadata.length == 0
    ) {
      this.$emit("setLoading", true);

      this.loadMetadata();
      //this.getTableData();

      this.$emit("setLoading", false);
    }
  },
  methods: {
    async submitForm(payload) {
      payload.webform_id = "commento";
      payload.id_dataset = this.id;
      payload.nome_dataset = this.titolo;

      let commentPromise = addComment(payload);

      try {
        await commentPromise;
        this.$notify({
          group: "userFeedback",
          title: "Successo!",
          text: "Il commento è stato inviato con successo.",
          type: "success",
        });
      } catch (err) {
        console.log(err);
        this.$notify({
          group: "userFeedback",
          title: "Errore!",
          text: "Un errore imprevisto ha impedito l'invio del coomento.",
          type: "error",
        });
      }
    },
    setLinks() {
      let zipDownloads = this.dataset?.resourceFormatUrls?.filter((r) => {
        return r.includes("ZIP");
      });
      this.dataset?.resourceFormatUrls?.forEach((link) => {
        let splitLink = link.split("|");
        let key = splitLink[0];
        let value = splitLink[1];

        var keys = key.split(":");
        if (key != "BINARY" && (key != "CSV" || zipDownloads.length < 1)) {
          this.links.push({
            key: link,
            name: keys[keys.length - 1],
            value: value,
          });
        }
      });
    },
    async getEntiIcon() {
      if (this.$store.getters["getEntiList"].length == 0) {
        let entiPromise = getEnti();

        try {
          let { data } = await entiPromise;

          this.$store.dispatch("setEntiList", {
            entiList: data,
          });
        } catch (err) {
          console.log(err);
        }
      }

      let filteredEnti = this.entiList.filter((r) => {
        return r.titolo.toLowerCase().includes(this.owner.toLowerCase());
      });
      this.ente = filteredEnti.length > 0 ? filteredEnti[0] : null;
    },
    async loadCommenti() {
      let commentsPromise = getComments(this.id);

      try {
        let { data } = await commentsPromise;

        this.commenti = data;
      } catch (err) {
        console.log(err);
      }
    },
    async getTableData(type) {
      let splittedId = this.id.split("smartdatanet.it_");
      let tableDataPromise = getTableData(type, splittedId[1]);

      try {
        let { data } = await tableDataPromise;

        let results = data.d.results;
        this.tableDataRows = data.d.__count;

        // set headers
        let tableHeaders = [];
        Object.keys(results[0]).forEach((header) => {
          if (
            header != "__metadata" &&
            header != "internalId" &&
            header != "datasetVersion" &&
            header != "idDataset"
          ) {
            let headerObj = {
              text: header,
              value: header,
            };
            tableHeaders.push(headerObj);
          }
        });
        this.tableHeaders = tableHeaders;

        // set data
        let tableData = [];
        for (let i = 0; i < results.length && i < 10; i++) {
          Object.keys(results[i]).forEach((key) => {
            if (
              results[i][key] &&
              results[i][key].toString().includes("/Date(")
            ) {
              let milliseconds = results[i][key]
                .replace("/Date(", "")
                .replace(")/", "");
              results[i][key] = this.$options.filters.tableDate(milliseconds);
            }
          });
          tableData.push(results[i]);
        }
        this.tableData = tableData;
      } catch (err) {
        console.log(err);
      }
    },
    async loadMetadata() {
      let splittedId = this.id.split("smartdatanet.it_");
      let metadataPromise = getDatasetMetadata(splittedId[1]);

      try {
        let { data } = await metadataPromise;

        let results = data;
        let metaType = results.type.includes('stream') ? 'Measures' : 'DataEntities';
        this.metadata = results.dcat;
        this.columnDetailData = results.components;
        this.metadataUrl = results.detailUrl;
        this.getTableData(metaType);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.dataset-title {
  font: normal normal bold 28px/36px Open Sans;
  letter-spacing: 0px;
}
.section-title {
  font: normal normal bold 27px/37px Open Sans;
  letter-spacing: 0px;
}
.dataset-description {
  font: normal normal normal 14px/28px Open Sans;
  letter-spacing: 0px;
}
.info-title {
  font: normal normal normal 14px/10px Open Sans;
  letter-spacing: 0px;
}
.text {
  font: normal normal bold 14px/18px Open Sans;
  letter-spacing: 0px;
  word-break: break-word;
}
.tag-chip:hover:before {
  opacity: 0;
}
.comments-container {
  border-radius: 2%;
  background: rgba(0, 0, 0, 0.06);
  max-width: 600px;
}
.links-list {
  a {
    text-decoration: none;
    color: $text-dark;
  }
}
.downloads-numbers {
  color: #2ab5b9;
  font: normal normal bold 54px/74px Open Sans;
  letter-spacing: 0px;
}
.downloads-numbers-text {
  font: normal normal normal 18px/24px Open Sans;
  letter-spacing: 0px;
}
a {
  color: #2ab5b9;
}
</style>