<template>
  <v-menu open-on-hover offset-y transition="slide-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <!-- button -->
      <v-btn v-bind="attrs" v-on="on" text class="d-flex align-center"
        ><v-icon class="share-icon">share</v-icon
        ><span class="share-text px-sm-4">Condividi</span>
      </v-btn>
    </template>
    <!-- share list -->
    <v-list>
      <v-list-item>
        <share-network
          network="facebook"
          :url="shareUrl"
          title="Portale Open Data."
          description="Portale Open Data."
          hashtags="opendata"
        >
          <div class="share-link v-btn v-btn--text theme--light v-size--default" text
            ><img
              width="25"
              height="25"
              :src="require('@/assets/facebook_blue.svg')"
              class="mr-2"
            />
            Facebook</div
          >
        </share-network>
      </v-list-item>
      <v-list-item>
        <share-network
          network="twitter"
          :url="shareUrl"
          title="Portale Open Data."
          description="Portale Open Data."
          hashtags="opendata"
        >
          <div class="share-link v-btn v-btn--text theme--light v-size--default" text
            ><img
              width="25"
              height="25"
              :src="require('@/assets/twitter_blue.svg')"
              class="mr-2"
            />
            Twitter</div
          >
        </share-network>
      </v-list-item>
      <!-- <v-list-item>
        <share-network
          network="linkedin"
          :url="shareUrl"
          title="Portale Open Data."
          description="Portale Open Data."
          hashtags="opendata"
        >
          <v-btn class="share-link" text
            ><v-img
              width="25"
              height="25"
              :src="require('@/assets/linkedin_blue.svg')"
              class="mr-2"
            ></v-img
            >LinkedIn</v-btn
          >
        </share-network>
      </v-list-item> -->
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "ShareButton",
  data () {
    return {
      shareBaseUrl: process.env.VUE_APP_SHARE_BASE_URL,
    }
  },
  computed: {
    shareUrl () {
      return this.shareBaseUrl + "/#" + this.$route.path
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.share-icon {
  font-size: 30px;
  line-height: 68px;
  color: $text-dark;
}
.share-text {
  color: #2f4595;
  font: normal normal normal 16px/40px Open Sans;
  letter-spacing: 0px;
}
.share-link {
  font: normal normal normal 16px/40px Open Sans;
  letter-spacing: 0px;
  color: #2f4595;
}
</style>
