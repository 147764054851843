<template>
  <v-container class="back">
    <v-btn class="my-2 px-0" text color="#304796" @click="goBack">
      <v-icon left class="back-icon">keyboard_arrow_left</v-icon>
      <span class="back-text text-capitalize">Indietro</span>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "BackButton",
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.back {
  .back-icon {
    font-size: 28px;
  }
  .back-text {
    font: normal normal 600 16px Open Sans;
    letter-spacing: 0;
  }
}
</style>
