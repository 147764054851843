<template>
  <v-row class="comment-section pa-4">
    <v-col class="col-12">
      <v-card class="pa-4 card"  width="fit-content" max-width="80%" color="#2ab5b9">
        <span class="comment-name user-text pt-4 pl-4">{{ comment.nome }}</span>
        <v-card-text class="pl-8 pr-8 user-text" v-html="comment.commento"></v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 justify-end">
      <v-card class="pa-4 float-right text-right card" width="fit-content" max-width="80%">
        <span class="comment-name pt-4 pr-4">dati.piemonte</span>
        <v-card-text class="pl-8 pr-8 comment-text" v-html="comment.risposta"></v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DatasetComment",
  props: {
    comment: { type: Object, required: true },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.comment-section {
  .card {
    background-color: #ffffff;
  }
  .user-name {
    color: $text-white;
  }
  .user-text {
    color: #ffffff;
  }
  .comment-text {
    color: $text-dark;
  }
  .comment-name {
    font: normal normal bold 14px/18px Open Sans;
    letter-spacing: 0px;
  }
}
</style>
